.container {
  width: calc(100% - 1px);
}

.filter_row {
  display: flex;
  justify-content: right;
}

.break_word {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.link {
  text-decoration: none !important;
}

.no_rows_text,
.grid_loader {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.grid_loader {
  z-index: 1;
  backdrop-filter: blur(5px);
}

.filter_container {
  width: 500px;
}

.notification {
  position: relative;
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 12px;
  padding: 1px 7px;
  border-radius: 50%;
  background: #DD4B39;
  border: 3px solid white;
  color: white;
}

.search_input{
  width: 400px;
  margin: 0 8px;
}

.bulk_send_email {
  cursor: pointer;
}

.bulk_send_email:hover {
  text-decoration: underline;
  color: #DD4B39;
  
}

.disabled_bulk_email {
  cursor: not-allowed;
}

.table_cell_checkbox label {
  margin: 0 !important;
}