.icon_style {
  color: #dd4b39;
  margin: 0 2px;
}

.disabled_icon_style {
  color: #a9a9a9;
  margin: 0 2px;
}

.link_container {
  text-align: right;
  cursor: pointer;
}
