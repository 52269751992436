.cursor-pointer {
  cursor: pointer;
}

.MuiDataGrid-columnHeaders {
  background-color: #dd4b39;
  color: white;
}

.MuiDataGrid-columnHeaders svg {
  fill: white;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none;
}

.data-grid a {
  text-decoration: none;
}

.page-title {
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0;
  color: #000000;
}

.data-grid .MuiDataGrid-virtualScroller {
  min-height: 70px;
}

.data-grid .MuiDataGrid-row {
  cursor: pointer;
}

.current-user-loading {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.nav-button {
  width: fit-content;
}

.d-flex {
  display: flex;
}

.d-none{
  display: none;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-10 {
  width: 10%;
}

.w-0 {
  width: 0px;
}

.w-transition {
  transition: ease-in-out 0.5s;
}

.overflow-hidden {
  overflow: hidden;
}

.active-nav {
  border-bottom: 3px solid #fff;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.left {
  padding: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content:flex-start !important;
}

.right {
  padding: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content:flex-end !important;
}

.center {
  padding: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content:center !important;
}
