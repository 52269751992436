.login-container{
    height: 100vh;
    display: flex;
    justify-content: center;
    filter: blur(5px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
}

.login-container-box{
    display: flex;
    width:max-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000000;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.45);
}

.login-container-inner-box{
    display: flex;
    width: auto;
    background: rgba(255, 255, 255, 0.81);
}

.login-company-logo{
    width: auto;
    display: flex;
    justify-content: center;
}

.login-divide-line{
    width: auto;
    height: auto;
}

.login-project-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.login-project-title-text{
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: #DD4B39;
}