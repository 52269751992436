.page-name {
  border-left: 2px solid white;
}

#username {
  border-right: solid 1px #fff;
  margin-right: 10px;
  padding-right: 10px;
}

#logout-icon {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
}

.user-name {
  display: flex;
  align-items: center;
}

#logout-icon {
  vertical-align: middle;
}

.app-name {
  width: "200px";
  flex: 2;
}

.brand-name {
  flex: 1.8;
}
