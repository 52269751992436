.done-icon {
    margin-right: 10px;
    margin-left: 10px;
}

.icon {
    color:rgba(0, 0, 0, 0.5);
}

.icon:hover {
    color:#dd4b39;
}

.wsr-input {
    display: flex;
}

.wsr-icons {
    margin-top: 15px;
}

.input-label {
   
}
.disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;        
}
.project-details-title-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 30px;
}

.wsr-loader-color {
    color: #dd4b39 !important;
    margin-right: 10px;
    margin-left: 10px;
    height: 24px !important;
    width: 24px !important;
}

.project-details-loading {
    height: calc(100vh - 200px);
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.app-loading {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.project-details-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
}

.project-details-form-field {
    padding: 5px 20px;
}

.project-details-form-action {
    padding: 10px 30px;
    display: flex;
    justify-content: end;
}

.project-details-save-button {
    margin-left: 16px !important;
}

.error {
   color: #d32f2f !important;
}

.label-content {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: flex-start;
}

.filter-container {
    width: 500px;
}