.container {
  width: calc(100%-1px);
}
.theme_color {
  background-color: #dd4b39;
  color: white;
}

/* Width */
scrollbar {
  width: 10px; /* Standardized for all browsers */
}

/* Track */
scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
scrollbar-thumb {
  background: #dd4b39;
}

/* Handle on hover */
scrollbar-thumb:hover {
  background: #555;
}

/* Legacy browser compatibility (optional) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #dd4b39;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
